import { noop } from 'lodash';
import { createContext, PropsWithChildren, useState } from 'react';
import { EventFormData } from '~/types';

// Create Context Object
export const EventFormContext = createContext<{
  eventFormData: EventFormData | null;
  setEventFormData: React.Dispatch<React.SetStateAction<EventFormData | null>>;
}>({
  eventFormData: null,
  setEventFormData: noop,
});

// Create a provider for components to consume and subscribe to changes
export const EventFormProvider = ({ children }: PropsWithChildren<{}>) => {
  const [eventFormData, setEventFormData] = useState<EventFormData | null>(null);

  return (
    <EventFormContext.Provider
      value={{
        eventFormData,
        setEventFormData,
      }}
    >
      {children}
    </EventFormContext.Provider>
  );
};
